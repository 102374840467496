<!--
 * @Author: Dyf
 * @Date: 2023-02-18 18:24:03
 * @LastEditors: Dyf
 * @LastEditTime: 2023-06-30 17:47
 * @Description: 个人信息
-->
<style lang="scss" scoped>
.page-person {
  @include innerPage;
  @include pageTitle;
  input{
    background: transparent;
    outline: none;
  }

  .person {
    &-box {
      width: calc(100% - 80px);
      height: 250px;
      margin: 54px 58px 0 22px;
      background: #f6f7fc;
      border-radius: 10px;
      box-sizing: border-box;
      padding-left: 60px;
      @include flexBox;

      .avatar-uploader {
        width: 145px;
        height: 145px;
        border-radius: 50%;
        border: 2px solid #e8eaf3;
        position: relative;

        .camera {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          border: 2px solid #ededed;
          background: #fff;
          position: absolute;
          bottom: 0;
          right: 8px;
          text-align: center;
          line-height: 32px;
          color: #cbcbcb;
          font-size: 18px;
        }

        .avatar,
        .el-upload {
          width: 100%;
          height: 100%;
        }

        .avatar {
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }

    &-info {
      margin-left: 36px;
      color: #0e0e0e;

      .pf {
        font-size: 18px;
        line-height: 2em;
        margin: 0.4em 0 0.8em;
      }

      h3 {
        input {
          height: 46px;
          line-height: 46px;
          border: none;
          background: transparent;
          font-size: 24px;
          font-weight: bold;
          outline: none;
        }

        .el-button {
          padding-left: 0;
          margin-right: -74px;
        }
      }

      .edit-pwd {
        color: #2ac293;
        cursor: pointer;
        @include flexBox;

        .iconfont {
          font-size: 18px;
          margin-right: 4px;
        }
      }
    }
  }
}

.dialog-container {
  padding: 42px 20px 14px;
  box-sizing: border-box;
}
</style>

<template>
  <section class="page-person">
    <div class="page-title">
      <h3>个人信息</h3>
      <el-button class="light" type="custom_primary" size="small" @click="$router.go(-1)">
        返回
      </el-button>
    </div>
    <div class="person-box">
      <el-upload class="avatar-uploader" :action="$upload.imgAction" :show-file-list="false" :accept="$upload.imgAccept"
        :before-upload="$beforImgUpload" :on-success="handleAvatarSuccess">
        <el-avatar class="avatar" fit="cover" :size="145" :src="formatfile(userInfo.scuse_image)">
          <img src="@assets/images/empty_avatar.png" />
        </el-avatar>
        <p class="camera iconfont">&#xe621;</p>
      </el-upload>
      <div class="person-info">
        <h3 class="bold">
          <input v-model="userData.scuse_name"
            :style="{ width: `${userData.scuse_name && userData.scuse_name.length == 0 && 24 || userData.scuse_name && userData.scuse_name.length * 24 || 24}px` }"
            ref="nameIpt" type="text" maxlength="20" :readonly="!nameEdit" @blur="nameEdit = false; setUser()"
            @input="userData.scuse_name = userData.scuse_name.replace(/\s+/g, '')" />
          <el-button type="text" class="success" @mousedown.native="iptFocus">
            <i class="iconfont">&#xe61e;</i>修改
          </el-button>
        </h3>
        <!-- userInfo.scuse_phone -->
        <p class="pf">{{ userInfo.scuse_account }}</p>
        <p class="edit-pwd" @click="personDialog = true">
          <span class="iconfont">&#xe61e;</span>
          <span>修改密码</span>
        </p>
      </div>
    </div>
    <el-dialog title="修改密码" width="558px" :show-close="false" :visible.sync="personDialog">
      <div class="dialog-container">
        <el-form label-width="140px" ref="passwordForm" :rules="passwordRules" :model="passwordForm">
          <el-form-item label="旧密码" prop="scuse_password">
            <el-input v-model.trim="passwordForm.scuse_password" type="password" maxlength="16" />
          </el-form-item>
          <el-form-item label="新密码(8~16位)" prop="scuse_change_pwd">
            <el-input v-model.trim="passwordForm.scuse_change_pwd" type="password" maxlength="16" />
          </el-form-item>
          <el-form-item label="确认新密码(8~16位)" prop="scuse_change_pwd_confirm">
            <el-input v-model.trim="passwordForm.scuse_change_pwd_confirm" type="password" maxlength="16" />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="custom_info" size="small" @click="hideDialog">
          取 消
        </el-button>
        <el-button type="custom_primary" size="small" @click="handleConfirm" :disabled="btnload" v-loading="btnload">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { validPassword } from "@utils/validate";
import { changePassword, userInfoEdit } from "@api/schoolManager";
import { formatFile } from "@utils"
import { mapActions, mapState } from "vuex";
export default {
  name: "home_person",
  computed: {
    ...mapState("user", ["userInfo"]),
    formatfile() {
      return function (url) {
        return formatFile(url)
      }
    }
  },
  watch: {
    userInfo: {
      handler(newVal) {
        this.userData = { ...newVal };
      },
      immediate: true,
    },
  },
  data() {
    // 密码校验
    let validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      } else if (value.length < 8 || value.legnth > 16) {
        callback(new Error("请输入8~16位密码"));
      } else if (!validPassword(value)) {
        callback(new Error("密码包含数字、大写字母、小写字母、特殊字符"));
      } else {
        callback();
      }
    };
    // 再次输入密码校验
    let validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.passwordForm.scuse_change_pwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      userData: {},
      passwordForm: {},
      personDialog: false,
      btnload: false,
      nameEdit: false,
      /* 修改密码表单校验规则 */
      passwordRules: {
        scuse_password: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        scuse_change_pwd: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        scuse_change_pwd_confirm: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    ...mapActions("user", ["gainUserInfo"]),
    /** 上传图片成功 */
    async handleAvatarSuccess({ data, msg }) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      this.userData = {
        ...this.userData,
        scuse_image: data.address,
      }
      this.setUser();
    },
    /** 修改用户信息 */
    async setUser() {
      if (!this.userData.scuse_name) {
        this.userData.scuse_name = this.userInfo.scuse_name;
        return;
      }
      let params = {
        scuse_name: this.userData.scuse_name,
        scuse_account: this.userData.scuse_account,
        scuse_phone: this.userData.scuse_phone,
        scuse_issuper: this.userData.scuse_issuper,
        scuse_image: this.userData.scuse_image,
        scuse_status: this.userData.scuse_status,
      }
      const res = await userInfoEdit(params);
      if (res.status == 200) {
        this.gainUserInfo();
      }
    },
    /** 修改密码 */
    handleConfirm() {
      this.$refs.passwordForm.validate(async (valid) => {
        if (valid) {
          this.btnload = true;
          let params = {
            ...this.passwordForm,
          };
          const res = await changePassword(params);
          this.btnload = false;
          if (res.status == 200) {
            //删除强制修改密码
            sessionStorage.removeItem("updatePwd");
            this.hideDialog();
            this.$message.success("修改成功");
          }
        }
      });
    },
    iptFocus(e) {
      this.nameEdit = true;
      this.$refs.nameIpt.focus()
      this.$nextTick(() => {
        this.$refs.nameIpt.setSelectionRange(this.userData.scuse_name.length, this.userData.scuse_name.length)
      })
      e.preventDefault();
    },
    hideDialog() {
      this.$refs.passwordForm.resetFields();
      this.passwordForm = {};
      this.personDialog = false;
    }
  },
};
</script>
